<template>
  <div id="webform">
    <p v-if="successMsg">{{ successMsg }}</p>
    <form v-else>
      <label for="subject">Subject</label>
      <input type="text" id="subject" v-model="payload.subject" />
      <label for="classification">Category</label>
      <select id="classification" v-model="payload.classification">
        <option value="misc" selected>Miscellaneous</option>
      </select>
      <label for="category">Sub-category</label>
      <input id="category" type="text" v-model="payload.subCategory" />
      <label for="description">Description</label>
      <textarea id="description" v-model="payload.description"></textarea>
    </form>
    <div><button class="button" @click.prevent="submit()">Submit</button></div>
  </div>
</template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import validator from 'validator'
import purify from 'dompurify'
const _blacklist = '\{\}\<\>\`\`' // eslint-disable-line no-useless-escape

export default {
  data () {
    return {
      successMsg: '',
      payload: {
        subject: '',
        classification: '',
        subCategory: '',
        description: '',
        contactId: this.user.profile.extradata.supportId,
        departmentId: '197426000000006907'
      }
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    ...mapState({
      user: ({ App }) => App.user,
    })
  },
  methods: {
    ...mapActions(['profileUpdate']),
    submit: function () {
      if (!this.payload.contactId) {
        // create support contact record on zoho desk
        this.$http.post(`${this.appconfig.API_URL}/contact`, this.user).then(d => {
          console.info(' == posted contact')
          console.info(' created contact', d)
          const contact = JSON.parse(d.data.data)
          this.payload.contactId = contact.id
          this.$http
            .post(`${this.appconfig.API_URL}/ticket`, this.sanitize(this.payload))
            .then(d => {
              console.info(' created ticket', d)
              this.successMsg =
                "Created new support ticket. We'll get back to you as soon as we can."
              this.$emit('hide.support')
            })
          // update profile with new zoho customer record.
          const _profile = _.assign({}, this.user.profile)
          _profile.extradata.supportId = contact.id
          this.profileUpdate({ user: this.user, profile: _profile })
        })
      } else {
        this.$http.post(`${this.appconfig.API_URL}/ticket`, this.payload).then(d => {
          console.info(' created ticket', d)
          this.successMsg =
            "Created new support ticket. We'll get back to you as soon as we can."
          this.$emit('hide.support')
        })
      }
    },
    sanitize: function (data) {
      return {
        subject: validator.blacklist(
          purify.sanitize(data.subject, { ALLOWED_TAGS: [] }),
          _blacklist
        ),
        classification: data.classification,
        subCategory: data.subCategory,
        description: validator.blacklist(
          purify.sanitize(data.description, { ALLOWED_TAGS: [] }),
          _blacklist
        ),
        contactId: data.contactId,
        departmentId: data.departmentId
      }
    }
  }
}
</script>

<style>
#webform {
  position: absolute;
  top: 2.25rem;
  right: 0;
  width: 15rem;
  /*height: ;*/
  padding: 10px;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.2);
  border: #aaa;
  border-radius: 4px;
  z-index: 10000;
  background-color: #fff !important;
}
#webform form {
  position: relative;
}
</style>
