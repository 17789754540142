<template>
  <div class="m-carousel">
    <div
      class="m-carousel-wrapper"
      :style="ulStyle"
      @mouseenter="pausePlay"
      @mouseout="goPlay"
    >
      <div
        class="m-carousel-item"
        :style="{
          width: 100 / count + '%',
          cursor: item.dest && item.dest.url ? 'pointer' : 'default'
        }"
        v-for="(item, index) in itemset"
        :key="index"
      >
        <img
          :src="item.src"
          :alt="item.alt"
          :class="{ link: item.dest && item.dest.url }"
          @click="resolveRoute(item.dest)"
        />
      </div>
    </div>
    <div v-if="showArrows">
      <div class="m-carousel-arrow carousel-arrow-left" @click="turn(-1)"></div>
      <div class="m-carousel-arrow carousel-arrow-right" @click="turn(1)"></div>
    </div>
    <div v-if="showDots">
      <div class="dots-wrapper">
        <span
          class="carousel-dot"
          v-for="i in count"
          :key="i"
          :class="{ 'carousel-dot-selected': currentIndex === i }"
          @click="handleDotClick(i)"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$watch('$lang', () => {
      this.itemset = this.items.slice(0, 1)
    })
  },
  data () {
    return {
      currentIndex: 0,
      count: this.items.length,
      autoPlayFlag: null,
      itemset: this.items.slice(0, 1),
      ulStyle: {
        width: this.items.length * 100 + '%',
        left: '0',
        transitionDuration: this.speed + 's'
      }
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    resolveRoute: {
      type: Function,
      required: false,
      default: () => {}
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    speed: {
      type: Number,
      required: false,
      default: 1
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true
    },
    showDots: {
      type: Boolean,
      required: false,
      default: true
    },
    delay: {
      type: Number,
      required: false,
      default: 6
    }
  },
  methods: {
    next () {
      const first = this.slides.shift()
      this.slides = this.slides.concat(first)
    },
    previous () {
      const last = this.slides.pop()
      this.slides = [last].concat(this.slides)
    },
    turn (i) {
      if (this.items.length < 2) return
      this.currentIndex =
        this.currentIndex === this.count - 1 ? 0 : this.currentIndex + i
      this.itemset = this.itemset.concat([this.items[this.currentIndex]])
      this.ulStyle.left = '-100%'
      this.ulStyle.transition = 'left 1s'

      setTimeout(() => {
        this.ulStyle.transition = 'none'
        this.itemset.shift()
        this.ulStyle.left = '0'
      }, 1000)
    },
    goPlay () {
      if (this.items.length < 2) return
      if (this.autoplay) {
        this.autoPlayFlag = setInterval(() => {
          this.turn(1)
        }, this.delay * 1000)
      }
    },
    pausePlay () {
      if (this.items.length < 2) return
      clearInterval(this.autoPlayFlag)
    },
    handleDotClick (i) {
      this.currentIndex = i
    },
    goTo (url) {
      if (url) this.$router.go(url)
    }
  },
  ready () {
    this.goPlay()
  }
}
</script>
<style type="text/css">
* {
  margin: 0;
  padding: 0;
}
.m-carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.m-carousel .m-carousel-wrapper {
  height: auto;
  overflow: hidden;
  position: relative;
  /* right: 0; */
  /* transition: left 1s; */
}
.m-carousel .m-carousel-item {
  display: inline-block;
  height: 100%;
  text-align: center;
}
.m-carousel .m-carousel-item > img {
  display: inline-block;
  max-width: 100%;
}
.m-carousel .m-carousel-item > img.link {
  cursor: pointer;
}
.m-carousel .m-carousel-arrow {
  display: inline-block;
  color: #2196f3;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 100;
  padding: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.m-carousel .m-carousel-arrow.carousel-arrow-right {
  right: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%232bb8aa'%2F%3E%3C%2Fsvg%3E");
}
.m-carousel .m-carousel-arrow.carousel-arrow-left {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%232bb8aa'%2F%3E%3C%2Fsvg%3E");
  left: 0;
}
.m-carousel .dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}
.m-carousel .dots-wrapper .carousel-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #ccc;
  margin: 6px;
  cursor: pointer;
  border-radius: 6px;
}
.m-carousel .dots-wrapper .carousel-dot:hover {
  border: 1px solid #2bb8aa;
}
.m-carousel .dots-wrapper .carousel-dot.carousel-dot-selected {
  background: #2bb8aa;
}
</style>
