import _ from 'lodash/fp'
import { LOGOUT, GOT_CART, CLEAR_CART } from '@/store/mutation-types'

const mutations = {
  [LOGOUT] (state) {
    state.cart = null
  },
  [GOT_CART] (state, payload) {
    if (payload.type) state.cart = _.assign({}, payload.cart)
  },
  [CLEAR_CART] (state) {
    state.cart = null
  }
}

export default mutations
