// import _ from 'lodash/fp'
import * as types from '../../mutation-types'

const actions = {
//
// ECERTIFICATES
//
  getCerts ({ commit }) {
    return this.$http
      .get('/giftcards?populate=true')
      .then(data => {
        commit(types.GOT_CERTS, data.data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },
  getCert ({ commit }, id) {
    return this.$http
      .get(`/giftcards/${id}?populate=true`)
      .then(data => commit(types.GOT_CERT, data.data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },
  receiptCerts ({ commit }, certs) {
    commit(types.GOT_CERTS, certs)
  }
}

export default actions
