import _ from 'lodash/fp'
import { LOGOUT, GOT_CERT, GOT_CERTS } from '@/store/mutation-types'

const mutations = {
  [LOGOUT] (state) {
    state.cart = null
  },
  [GOT_CERT] (state, cert) {
    if (cert.err) {
      console.error(':: error getting gift certificate', cert)
      return
    }
    state.cert = _.assign({}, cert)
  },
  [GOT_CERTS] (state, certs) {
    state.certs = typeof certs === 'string' ? [] : certs
  }
}

export default mutations
