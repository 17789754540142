<template src="@/../../../templates/brand/components/templates/Page.html"> </template>

<script>
import { mapState, mapGetters } from 'vuex'
import kramed from 'kramed'

export default {
  mounted () {
    kramed.setOptions({
      renderer: new kramed.Renderer(),
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: true,
      smartLists: true,
      smartypants: true
    })
  },
  data () {
    return {
      story: {},
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    content () {
      if (!this.story.content) return ''
      return kramed(this.story.content[this.$i18n.locale].body)
    }
  },
  watch: {
    '$route.params.page': function() {
      this.$http.get('/pages/' + this.$route.params.page)
      .then(res => {
        console.info(' ::: page', res)
        this.story = res.data
      })
    }
  },
  created () {
    this.$http.get('/pages/' + this.$route.params.page)
    .then(res => {
      console.info(' ::: page', res)
      this.story = res.data
    })
  }
}
</script>

<style>
@media only screen and (min-width: 100.063em) {
  /* greater than xlarge (1601px) -------------------------------------------------------- */
}
@media only screen and (max-width: 100em) {
  /* up to xlarge (1600px) -------------------------------------------------------- */
}
@media only screen and (max-width: 80em) {
  /* up to large (1280px) -------------------------------------------------------- */
}
@media only screen and (max-width: 64em) {
  /* up to medium (1024px) ------------------------------------------------------- */
}
@media only screen and (max-width: 40em) {
  /* up to small ----------------------------------------------------------------- */
}
</style>
