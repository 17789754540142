// UI chrome
export const PAGE_MENU = 'PAGE_MENU'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FLASH_ERROR = 'FLASH_ERROR'
export const MERGE_CONFIG = 'MERGE_CONFIG'
export const HOME_CONFIG = 'HOME_CONFIG'

// locale
export const ACTIVE = 'ACTIVE;'

// users
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER'
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN'
export const GOT_USER = 'GOT_USER'
export const GOT_USERS = 'GOT_USERS'
export const ADDED_USER = 'ADDED_USER'
export const UPDATED_USER = 'UPDATED_USER'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const HAVE_PREORDER = 'HAVE_PREORDER'
export const DELETED_USER = 'DELETED_USER'

// roles
export const GOT_ROLE = 'GOT_ROLE'
export const GOT_ROLES = 'GOT_ROLES'

// permissions
// export const GOT_PERMISSION = 'GOT_PERMISSION';
// export const GOT_PERMISSIONS = 'GOT_PERMISSIONS';
// export const ADDED_PERMISSION = 'ADDED_PERMISSION';
// export const UPDATED_PERMISSION = 'UPDATED_PERMISSION';
// export const DELETED_PERMISSION = 'DELETED_PERMISSION';

// content/pages/nav
export const GOT_CONTENT = 'GOT_CONTENT'
export const GOT_PAGES = 'GOT_PAGES'
export const GOT_PAGE = 'GOT_PAGE'
export const ADDED_CONTENT = 'ADDED_CONTENT'
export const UPDATED_CONTENT = 'UPDATED_CONTENT'
export const REMOVED_CONTENT = 'REMOVED_CONTENT'

export const GOT_CATEGORY = 'GOT_CATEGORY'
export const GOT_CATEGORIES = 'GOT_CATEGORIES'

export const SET_CONTEXT = 'SET_CONTEXT'

// products
export const GOT_PRODUCT = 'GOT_PRODUCT'
export const GOT_PRODUCT_DETAIL = 'GOT_PRODUCT_DETAIL'
export const GOT_PRODUCTS = 'GOT_PRODUCTS'
export const GOT_COLLECTION_LIST = 'GOT_COLLECTION_LIST'
export const GOT_COLLECTION_PRODUCTS = 'GOT_COLLECTION_PRODUCTS'
export const FILTER_COLLECTION_PRODUCTS = 'FILTER_COLLECTION_PRODUCTS'
export const SHOW_PRODUCT_FAMILY = 'SHOW_PRODUCT_FAMILY'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

export const GOT_CERTS = 'GOT_CERTS'
export const GOT_CERT = 'GOT_CERT'

// cart and order
export const GOT_CART = 'GOT_CART'
export const GOT_CUSTOMORDER_PRODUCTS = 'GOT_CUSTOMORDER_PRODUCTS'
export const CART_DATA = 'CART_DATA'
export const CLEAR_CART = 'CLEAR_CART'
export const START_ORDER = 'START_ORDER'
export const NEW_ORDER = 'NEW_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const ORDER_IS_RECEIPT = 'ORDER_IS_RECEIPT'
export const GOT_ORDER = 'GOT_ORDER'
export const GOT_ORDERS = 'GOT_ORDERS'
export const UPDATE_CREDIT = 'UPDATE_CREDIT'
export const ADD_TXN = 'ADD_TXN'
export const CLEAR_TXN = 'CLEAR_TXN'
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const ORDER_COMPLETE = 'ORDER_COMPLETE'
export const UPDATE_SHIPPING_RATE = 'UPDATE_SHIPPING_RATE'
export const UPDATE_METHOD = 'UPDATE_METHOD'
export const SET_CC_PROFILE = 'SET_CC_PROFILE'
export const SHIPPING_ADDRESS = 'SHIPPING_ADDRESS'
export const BILLING_ADDRESS = 'BILLING_ADDRESS'
export const CLEAR_SHIPPING = 'CLEAR_SHIPPING'

// collections
export const GOT_COLLECTION = 'GOT_COLLECTION'
export const SET_COLLECTION = 'SET_COLLECTION'
export const GOT_COLLECTIONS = 'GOT_COLLECTIONS'
export const SHOW_COLLECTION_PRODUCTS = 'GOT_COLLECTION_PRODUCTS'

// programs
export const GOT_PROGRAMS = 'GOT_PROGRAMS'
export const GOT_PROGRAM = 'GOT_PROGRAM'
export const SET_PROGRAM = 'SET_PROGRAM'
export const SHOW_PROGRAM_PRODUCTS = 'SHOW_PROGRAM_PRODUCTS'
export const GOT_PREORDER_PRODUCTS = 'GOT_PREORDER_PRODUCTS'
export const UPDATE_PROGRAM_SUMMARY = 'UPDATE_PROGRAM_SUMMARY'

// locations
export const GOT_LOCATION = 'GOT_LOCATION'
export const GOT_LOCATIONS = 'GOT_LOCATIONS'

// common
export const REQ_ERROR = 'REQ_ERROR'
