// import _ from 'lodash/fp'
import * as types from '../../mutation-types'

const actions = {
//
// CONTENT
//
  getPageMenu ({ commit }) {
    this.$http
      .get('/public/navigation')
      .then(data => commit(types.PAGE_MENU, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getPages ({ commit }) {
    this.$http
      .get('/pages')
      .then(data => commit(types.GOT_PAGES, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getPage ({ commit }, id) {
    this.$http
      .get(`/pages/${id}?populate=true`)
      .then(data => commit(types.GOT_PAGE, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getContent ({ commit }) {
    this.$http
      .get('/content?populate=true')
      .then(data => commit(types.GOT_CONTENT, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  addStory ({ commit }, data) {
    commit(types.ADDED_CONTENT, data)
  },

  editStory ({ commit }, id, data) {
    commit(types.UPDATED_CONTENT, data)
  },

  removeStory ({ commit }, data) {
    commit(types.REMOVED_CONTENT, data)
  }
}

export default actions
