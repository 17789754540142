import _ from 'lodash/fp'
import * as types from '../../mutation-types'

const actions = {
  async startOrder({ commit, dispatch }, payload) {
    const _defaults = {}
    if (payload.opts.shipping) {
      _defaults.shipping = payload.opts.shipping
      if (!payload.opts.shipping.email || !payload.opts.shipping.email.length)
        _defaults.shipping.email = payload.profile.email
      if (!payload.opts.shipping.phone || !payload.opts.shipping.phone.length)
        _defaults.shipping.phone = payload.profile.phone
    } else {
      _defaults.shipping = {}
    }
    if (payload.opts.billing) {
      _defaults.billing = payload.opts.billing
      if (!payload.opts.billing.email || !payload.opts.billing.email.length)
        _defaults.billing.email = payload.profile.email
      if (!payload.opts.billing.phone || !payload.opts.billing.phone.length)
        _defaults.billing.phone = payload.profile.phone
    } else {
      _defaults.billing = {}
    }
    // if (payload.opts.price && payload.opts.price.subttotal) _defaults.price.subtotal = payload.opts.subtotal
    // else _defaults.price = { subtotal: 0 }
    if (payload.opts.price) _defaults.price = payload.opts.price
    else _defaults.price = { subtotal: 0 }
    if (payload.opts.parentPrice)
      _defaults.parentPrice = payload.opts.parentPrice
    if (payload.opts.groupId) _defaults.groupId = payload.opts.groupId
    if (payload.opts.parent) {
      _defaults.parent = true
      _defaults.price.subtotal = payload.opts.parentPrice.subtotal
    }
    _defaults.collection = payload.opts.collection

    _defaults.items = payload.opts.items
    _defaults.purchaser = payload.opts.purchaser
    _defaults.paymentMethod = payload.opts.paymentMethod
    _defaults.type = payload.opts.type || 'standard'

    _defaults.shippingService = {
      type: payload.opts.shipper.type,
      rate: 0,
      billingWeight: payload.opts.shipper.shippingWeight
    }
    _defaults.shippingService = await dispatch('calculateOrderShipping', {
      order: _defaults,
      shipper: payload.opts.shipper
    })
    if (_defaults.shippingService.shippingTBD) {
      _defaults.price.shipping = 0
      _defaults.price.shippingTBD = true
    } else {
      _defaults.price.shipping = _defaults.shippingService.rate
    }
    if (payload.opts.parent) {
      _defaults.parent = true
      _defaults.parentPrice.shipping = _defaults.shippingService.rate
    }

    // if (payload.opts.shipper.type === 'flatrate') {
    //   _defaults.shippingService.carrier = 'Flat-rate'
    //   _defaults.shippingService.serviceCode = 'flat'
    //   _defaults.shippingService.currency = 'CAD'
    //   _defaults.shippingService.rate =
    //     _defaults.price.subtotal <= payload.opts.shipper.threshold
    //       ? payload.opts.shipper.base
    //       : _defaults.price.subtotal * (payload.opts.shipper.percent * 0.01)
    //   if (payload.opts.parent) {
    //     _defaults.parent = true
    //     _defaults.parentPrice.shipping = _defaults.shippingService.rate
    //   }
    //   _defaults.price.shipping = _defaults.shippingService.rate
    // }
    // if (payload.opts.shipper.type === 'tiered') {
    //   _defaults.shippingService.carrier = 'Tiered'
    //   _defaults.shippingService.serviceCode = 'tiered'
    //   _defaults.shippingService.currency = 'CAD'
    //   // const _i = payload.opts.shipper.tiers.findIndex(t => t < _defaults.price.subtotal)
    //   const sortedTiers = _.sortBy('ordervalue', payload.opts.collection.settings.shipping.tiers)
    //   const tier = sortedTiers.findIndex(t => _defaults.price.subtotal <= t.ordervalue)
    //   let moreprev = true
    //   let _sub = _defaults.price.subtotal
    //   const _finalTiers = sortedTiers.slice(0, tier + 1).reverse().reduce((acc, t, i, all) => {
    //     if (!moreprev) return acc
    //     if (!t.includeprev || i === all.length - 1) {
    //       moreprev = false
    //     }
    //     acc.push(t)
    //     return acc
    //   }, [])
    //   _defaults.shippingService.rate = _finalTiers.reverse().reduce((acc, t) => {
    //     const subportion = _sub > t.ordervalue ? t.ordervalue : _sub
    //     _sub = _sub > t.ordervalue ? _sub - t.ordervalue : _sub
    //       const val = (t.dollar ? t.rate : subportion * (t.rate * .01))
    //       acc += val
    //     return acc
    //   }, 0)
    //       // _i
    //     //   ? payload.opts.shipper.tiers[_i].rate
    //     //   : payload.opts.shipper.tierbase
    //   if (payload.opts.parent) {
    //     _defaults.parent = true
    //     _defaults.parentPrice.shipping = _defaults.shippingService.rate
    //   }
    //   _defaults.price.shipping = _defaults.shippingService.rate
    // }
    commit(types.START_ORDER, _defaults)
    return Promise.resolve(_defaults)
  },

  changeOrderCard({ commit }, payload) {
    return this.$http
      .post(`/orders/${payload.order._id}/changecard`, {
        paymentProfile: payload.profile
      })
      .then(res => {
        if (res.success)
          commit(types.UPDATE_ORDER, { paymentProfile: payload.profile })
        else console.error(' :: problem changing card', res)
      })
      .catch(err => commit(types.REQ_ERROR, err))
  },

  updateOrder({ commit }, data) {
    commit(types.UPDATE_ORDER, data)
  },

  createOrder({ commit }, order) {
    commit(types.NEW_ORDER, order)
  },

  completeOrder({ commit }, payload) {
    return this.$http
      .post(`/orders/${payload.id}`, payload.order)
      .then(data => {
        if (data.success) commit(types.ORDER_IS_RECEIPT)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  forgetOrder({ commit }) {
    commit(types.CLEAR_ORDER)
  },

  getOrder({ commit }, orderid) {
    return this.$http
      .get(`/orders/${orderid}?populate=true`)
      .then(data => {
        commit(types.GOT_ORDER, data.data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  contributeCredit({ commit }, credit) {
    // console.info('calling actions.contributeCredit')
    commit(types.UPDATE_CREDIT, credit)
  },

  mergeTxn({ commit }, res) {
    commit(types.ADD_TXN, res)
    // console.info('added txn to order')
  },

  removeTxn({ commit }) {
    commit(types.CLEAR_TXN)
  },

  cancelOrder({ commit }, id) {
    return this.$http
      .delete(`/orders/${id}`)
      .then(data => {
        commit(types.CLEAR_ORDER)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getOrders({ commit }) {
    return this.$http
      .get('/orders?populate=true')
      .then(data => {
        commit(types.GOT_ORDERS, data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  setOrderShipping({ commit }, opts) {
    commit(types.UPDATE_SHIPPING_RATE, _.assign({}, opts))
  },

  setOrderMethod({ commit }, method) {
    commit(types.UPDATE_METHOD, method)
  },

  setOrderProfile({ commit }, method) {
    commit(types.SET_CC_PROFILE, method)
  },

  closeOrder({ commit }) {
    commit(types.CLEAR_ORDER)
  },

  clearShippingData({ commit }) {
    commit(types.CLEAR_SHIPPING)
  },
  setShippingAddress({ commit }, address) {
    commit(types.SHIPPING_ADDRESS, address)
  },
  setBillingAddress({ commit }, address) {
    commit(types.BILLING_ADDRESS, address)
  },

  calculateOrderShipping({ rootState }, payload) {
    const _weight = payload.shipper.shippingWeight
    const order = payload.order
    // _.reduce((acc, i) => {
    //   acc += (i.product.weight * i.quantity)
    //   return acc
    // }, 0, order.items)

    if (order.shippingService.type === 'included') {
      return {
        type: 'included',
        rate: 0,
        carrier: 'Included',
        serviceCode: 'included',
        billingWeight: _weight
      }
    }
    if (order.shippingService.type === 'deferred') {
      return {
        type: 'deferred',
        rate: 0,
        carrier: 'Deferred',
        serviceCode: 'deferred',
        billingWeight: _weight
      }
    }
    const _subtotal = payload.order.price.subtotal //_.reduce((acc, i) => acc + i.totalPrice, 0, order.items)

    if (order.shippingService.type === 'flatrate') {
      const rate =
        _subtotal > payload.shipper.threshold
          ? payload.shipper.base
          : _subtotal * (payload.shipper.percent * 0.01)
      return {
        type: 'flatrate',
        rate,
        carrier: 'Flat-Rate',
        serviceCode: 'flat',
        billingWeight: _weight
      }
    } else if (order.shippingService.type === 'tiered') {
      const sortedTiers = _.sortBy('ordervalue', payload.shipper.tiers)
      const tier = sortedTiers.findIndex(t => _subtotal <= t.ordervalue)
      let service = order.shippingService
      service.carrier = 'TBD'
      service.serviceCode = 'tiered'
      service.currency = 'CAD'
      service.billingWeight = _weight
      // TBD if subtotal > top tier...
      // TODO: (TBD) -- design gap with rate when subtotal > top tier and RFQ...
      if (!~tier) {
        service.shippingTBD = true
        service.rate = 0 //rootState.App.constants.siteConfig.TBDShippingRate
      } else {
        let moreprev = true
        let _sub = order.price.subtotal
        const _finalTiers = sortedTiers
          .slice(0, tier + 1)
          .reverse()
          .reduce((acc, t, i, all) => {
            if (!moreprev) return acc
            if (!t.includeprev || i === all.length - 1) {
              moreprev = false
            }
            acc.push(t)
            return acc
          }, [])
        service.rate = _finalTiers.reverse().reduce((acc, t) => {
          const subportion = _sub > t.ordervalue ? t.ordervalue : _sub
          _sub = _sub > t.ordervalue ? _sub - t.ordervalue : _sub
          const val = t.dollar ? t.rate : subportion * (t.rate * 0.01)
          acc += val
          return acc
        }, 0)
      }
      return service
    } else if (
      _weight > rootState.App.constants.siteConfig.maxShippingWeight ||
      (order.shippingService.carrier &&
        rootState.App.constants.siteConfig.shipping[
          order.shippingService.carrier
        ].maxShippingWeight)
    ) {
      // set shippingTBD
      return {
        type: order.shippingService.type,
        shippingTBD: true,
        rate: rootState.App.constants.siteConfig.TBDShippingRate,
        currency: 'CAD',
        billingWeight: _weight
      }
    } else {
      return order.shippingService
    }
  }
}

export default actions
