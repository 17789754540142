// import _ from 'lodash/fp'
import actions from './actions'
import mutations from './mutations'

const state = {
  cart: null
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
