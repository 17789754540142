import * as types from '@/store/mutation-types'

const actions = {
  // change language
  activate ({ commit }, ln) {
    document.documentElement.lang = ln
    commit(types.ACTIVE, ln)
  },

  // global action to pass error message to view
  flashErr ({ commit }, msg) {
    commit(types.FLASH_ERROR, msg)
  },

  getHomeConfig ({ commit }) {
    return this.$http.get('/content/home-config').then(res => {
      commit(types.HOME_CONFIG, res.data.config)
    })
  },
  setContext ({ commit }, { context, products }) {
    commit(types.SET_CONTEXT, { path: context, products: products })
  }
}

export default actions
