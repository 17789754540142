import _ from 'lodash/fp'
import constants from '@/../../../templates/site/UI/common/common-constants'

const auth = {
  // User object will let us check authentication status
  user: {
    authenticated: false
  },

  // Send a request to the login URL and save the returned JWT
  login (ctx, creds, redirect, cb) {
    ctx.$http
      .post('public/login', creds)
      .then(res => {
        if (!res.data.success) {
          cb(res.body.msg, this.user, '')
          // Good! Auth!
        } else {
          this.user.authenticated = true
          this.user = _.assign(this.user, res.data.user)
          window.localStorage.setItem('id_token', res.data.id_token)
          window.localStorage.setItem('user_rec', JSON.stringify(this.user))
          ctx.$http.defaults.headers.common.Authorization = `Bearer ${res.data.id_token}`
          cb(null, this.user, redirect)
        }
      })
      .catch(err => {
        console.error(' :: login error', err)
        cb(err, this.user)
      })
  },

  signup (ctx) {
    return ctx.$http.post('public/signup').catch(err => new Error(err))
  },

  // To log out, we just need to remove the token
  logout (cb = () => false) {
    window.localStorage.removeItem('id_token')
    this.user = { authenticated: false }
    window.localStorage.setItem('user_rec', JSON.stringify(this.user))
    // return Promise.resolve(true);
    cb()
  },

  testPass (ctx, p) {
    const _headers = this.getAuthHeader()

    _headers.expose = ['Origin', 'Authorization']
    _headers['Content-Type'] = 'application/json'
    _headers['Access-Control-Allow-Origin'] = constants.ORIGIN

    return ctx.$http
      .post('users/test', {password: p})
      .catch(err => new Error(err))
  },

  getUser () {
    if (!this.user.authenticated) {
      const user =
        window.localStorage.getItem('user_rec') &&
        JSON.parse(window.localStorage.getItem('user_rec'))
      if (user) this.user = user
    }
    return this.user
  },

  updateUserProfile (profile) {
    if (this.user.authenticated) {
      this.user.profile = profile
      window.localStorage.setItem('user_rec', JSON.stringify(this.user))
    }
    return this.user
  },

  routePermitted (path, rules) {
    if (!path.permission) return true
    return rules[path.permission](this.user.role.level)
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader (array = false) {
    const _user = JSON.parse(window.localStorage.getItem('user_rec'))
    return !_user || !_user.authenticated
      ? {}
      : array
      ? ['Authorization', 'Bearer ' + window.localStorage.getItem('id_token')]
      : {
          Authorization: 'Bearer ' + window.localStorage.getItem('id_token')
        }
  },

  /**
   * NB: moved this to the end as WebPack sometimes deploys TDZ on call to getAuthHeader...
   */
  async checkAuth (ctx) {
    const jwt = await new Promise(resolve => {
      resolve(window.localStorage.getItem('id_token'))
    })

    if (jwt) {
      return ctx.$http
        .get('auth', {
          headers: {
            'Authorization': 'Bearer ' + jwt
          }
        })
        .then(res => {
          if (res.data.success) {
            this.user = _.assign(this.user, res.data.user)
            this.user.authenticated = true
            window.localStorage.setItem('id_token', res.data.id_token)
            window.localStorage.setItem('user_rec', JSON.stringify(this.user))
            ctx.$http.defaults.headers.common.Authorization = `Bearer ${res.data.id_token}`
            this.user.avatar = ''
            return { success: true, user: this.user }
          } else {
            return {success: false, message: res.data.message}
          }
        })
        .catch(err => {
          this.user = { authenticated: false }
          return new Error('not authorized', {
            success: false,
            err: err,
            user: this.user
          })
        })
    } else {
      this.user.authenticated = false
      if (ctx.$route.requiresAuth || !ctx.$route.meta.public) {
        return Promise.reject(
          new Error('not authenticated', {
            success: false,
            err: 'no token',
            user: this.user
          })
        )
      } else {
        return Promise.resolve('public')
      }
    }
    // return this.user.authenticated;
  },

  getConfig (ctx) {
    return ctx.$http
      .post('auth/config', {})
      .then(res => res)
      .catch(err => err)
  }
}

export default auth
