import { LOGOUT, GOT_LOCATIONS, GOT_LOCATION } from '@/store/mutation-types'

const mutations = {
  [LOGOUT] (state) {
    state.locations = []
    state.location = null
  },
  [GOT_LOCATIONS] (state, data) {
    state.locations = data
  },
  [GOT_LOCATION] (state, location) {
    state.location = location
  }
}

export default mutations
