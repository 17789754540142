import actions from './actions'
import mutations from './mutations'
import _c from '@/../../../templates/site/UI/common/constants.js'

const state = {
  user: {
    authenticated: false
  },
  constants: _c,
  showAlert: ''
  // flash: '',
  // homeConfig: {},
  // navigationContext: ''
}

export default {
  state,
  actions,
  mutations,
  getters: {
    const: state => state.constants
  }
}
