import {
  GOT_CONTENT,
  GOT_PAGES,
  GOT_PAGE,
  REQ_ERROR
} from '@/store/mutation-types'

const mutations = {
  [GOT_CONTENT] (state, content) {
    state.content = content
    state.pages = content.filter(cont => cont.type === 'Page')
  },
  [GOT_PAGES] (state, pages) {
    state.pages = pages
  },
  [GOT_PAGE] (state, page) {
    state.page = page
  },
  [REQ_ERROR] (state, err) {
    state.api_error = err
    console.error('Error: ', err)
    state.success = false
    state.error = true
  }
}

export default mutations
