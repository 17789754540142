<template>
  <div class="component-container">
    <component :is="view"></component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicHome from './HomePublic.vue'
import UserHome from './HomeUser.vue'

export default {
  name: 'Home',
  components: { PublicHome, UserHome },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    view () {
      return this.user.authenticated ? UserHome : PublicHome
    }
  },
  data () {
    return {
      // user: this.$store.state.user
    }
  }
}
</script>

<style></style>
