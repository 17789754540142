import Vue from 'vue'
import Vuex from 'vuex'

import App from './modules/app'
import Appui from './modules/appui'
import Content from './modules/content'
import Product from './modules/product'
import Location from './modules/location'
import Collection from './modules/collection'
import Cart from './modules/cart'
import Order from './modules/order'
import ECert from './modules/credit'

Vue.use(Vuex)

export default new Vuex.Store({
  // state: App.state,
  // mutations: App.mutations,
  // actions: App.actions,
  // getters: App.getters,
  modules: {
    App,
    Appui,
    Content,
    Product,
    Location,
    Collection,
    Cart,
    Order,
    ECert
  }
})
