import actions from './actions'
import mutations from './mutations'

const state = {
  pagenav: [],
  cartcount: { items: 0, units: 0, subtotal: 0, cartmirror: {} },
  customcount: { items: 0, units: 0, subtotal: 0, cartmirror: {} },
  customcarts: {},
  flash: '',
  homeConfig: {},
  navigationContext: ''
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
