// import _ from 'lodash/fp'
import * as types from '../../mutation-types'

const actions = {
  //
  // LOCATIONS
  //
  getLocation ({ commit }, id) {
    this.$http
      .get(`/locations/${id}?populate=true`)
      .then(data => commit(types.GOT_LOCATION, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getLocations ({ commit }, owner) {
    // return new Promise((resolve, reject) => {
    const _opts = { populate: true }
    if (owner) _opts.where = { owner }
    return this.$http
      .get(`/locations?populate=true`)
      .then(res => {
        commit(types.GOT_LOCATIONS, res.data.data)
        return res.data.data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
        return err
      })
    // })
  }
}

export default actions
