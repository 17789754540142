<template>
  <div class="row">
    <div
      class="small-12 medium-8 medium-offset-2 large-8 large-offset-2 xxlarge-6 xxlarge-offset-3 end columns countdown tight"
      align="center"
    >
      <div class="small-12 large-9 columns blurb tight" align="center">
        <p :class="$i18n.locale">{{ $t('client.home.timeLeft') }}</p>
      </div>
      <!-- blurb -->
      <!-- ON -->
      <div v-if="count && !wait" class="small-12 large-3 columns timer">
        <div class="small-3 columns segment-outer days" align="center">
          <div class="columns segment tight">
            {{ addZero(days) }}<br /><span>{{ $t('client.home.days') }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer hours tight" align="center">
          <div class="columns segment tight">
            {{ addZero(hours) }}<br /><span>{{ $t('client.home.hours') }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer minutes tight" align="center">
          <div class="columns segment tight">
            {{ addZero(minutes) }}<br /><span>{{
              $t('client.home.mins')
            }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer seconds tight" align="center">
          <div class="columns segment tight">
            {{ addZero(seconds) }}<br /><span>{{
              $t('client.home.secs')
            }}</span>
          </div>
        </div>
      </div>
      <!-- OFF -->
      <div v-else class="small-12 large-3 columns timer">
        <div class="small-3 columns segment-outer days" align="center">
          <div class="columns segment tight">
            00 <br /><span>{{ $t('client.home.days') }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer hours tight" align="center">
          <div class="columns segment tight">
            00 <br /><span>{{ $t('client.home.hours') }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer minutes tight" align="center">
          <div class="columns segment tight">
            00 <br /><span>{{ $t('client.home.mins') }}</span>
          </div>
        </div>
        <div class="small-3 columns segment-outer seconds tight" align="center">
          <div class="columns segment tight">
            00 <br /><span>{{ $t('client.home.secs') }}</span>
          </div>
        </div>
      </div>
      <!-- <div v-if="showNext && !count" class="small-12 columns notice"> -->
      <div v-if="!count" class="small-12 columns notice">
        <p v-if="nextDate" :class="$i18n.locale">
          {{ $t('client.home.nextBooking') }}
          {{ ($d(new Date(nextDate), 'short')) }}
        </p>
        <p v-else :class="$i18n.locale">
          {{ $t('client.home.nextBookingSoon') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  created () {
    this.countdown()
    this.$watch('target', function () {
      this.countdown()
    })
  },
  props: {
    target: [String, Object],
    leadingZero: {
      type: Boolean,
      default: true
    },
    wait: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String,
      default: () => new Date().toISOString()
    },
    showNext: {
      type: Boolean,
      default: true
    },
    nextDate: [String, Object]
  },
  data () {
    return {
      targetDate: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  computed: {
    count () {
      if (!this.target) return false
      if (!this.startDate || new Date() < new Date(this.startDate)) return false
      return new Date(this.startDate).getTime() < new Date().getTime()
    },
  },
  methods: {
    countdown () {
      this.targetDate = new Date(this.target).getTime()
      setInterval(() => {
        let diff = Math.floor((this.targetDate - new Date().getTime()) / 1000) //this.targetDate.diff(moment(), 'seconds');
        this.days = Math.floor(diff / 86400)
        diff = diff - this.days * 86400
        this.hours = Math.floor(diff / 3600)
        diff = diff - this.hours * 3600
        this.minutes = Math.floor(diff / 60)
        this.seconds = diff - this.minutes * 60
      }, 1000)
    },
    addZero (num) {
      let _num = num
      if (typeof num === 'string') _num = parseInt(num, 10)
      return this.leadingZero ? (_num < 10 ? '0'.concat(_num) : _num) : _num
    }
  }
}
</script>

<style></style>
