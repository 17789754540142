const regions = [
  {
    name: 'Canada',
    default: true,
    hideCountryField: true,
    countryCode: 'CA',
    postalcode: true,
    // postalcodeName: 'Postal Code',
    postalcodeLength: 7,
    postalcodePlaceholder: 'X0X 0X0',
    province: true,
    // provinceLabel: 'Province',
    provinceList: [
      { name: 'Alberta', provinceCode: 'AB' },
      { name: 'British Columbia', provinceCode: 'BC' },
      { name: 'Manitoba', provinceCode: 'MB' },
      { name: 'New Brunswick', provinceCode: 'NB' },
      { name: 'Newfoundland and Labrador', provinceCode: 'NL' },
      { name: 'Nova Scotia', provinceCode: 'NS' },
      { name: 'Northwest Territories', provinceCode: 'NT' },
      { name: 'Nunavut', provinceCode: 'NU' },
      { name: 'Ontario', provinceCode: 'ON' },
      { name: 'Prince Edward Island', provinceCode: 'PE' },
      { name: 'Quebec', provinceCode: 'QC' },
      { name: 'Saskatchewan', provinceCode: 'SK' },
      { name: 'Yukon', provinceCode: 'YT' },
    ],
  },
]

export default regions
