import actions from './actions'
import mutations from './mutations'

const state = {
  order: null,
  orders: []
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
