import actions from './actions'
import mutations from './mutations'

const state = {
  collections: [],
  collection: null,
  section: '',
  collectionLocale: '',
  programs: [],
  program: null
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
