import _ from 'lodash/fp'
import * as types from '../../mutation-types'

const actions = {
  //
  // COLLECTIONS
  //
  getCollections({ commit }) {
    return (
      this.$http
        .get('collections', { populate: false })
        // this.$http.get()
        .then(data => {
          commit(types.GOT_COLLECTIONS, data.data)
          return data
        })
        .catch(err => {
          console.log('Err: ', err)
          commit(types.REQ_ERROR, err)
        })
    )
  },

  getCollection({ commit }, url) {
    return this.$http
      .get({ url: url, params: { populate: true } })
      .then(data => {
        commit(types.GOT_COLLECTION, data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  setCollection({ commit }, url) {
    return new Promise(resolve => {
      commit(types.SET_COLLECTION, url)
      resolve(true)
    })
  },

  //
  // PROGRAMS
  //
  getPrograms({ commit }) {
    return this.$http
      .get('/programs', { populate: false })
      .then(data => {
        commit(types.GOT_PROGRAMS, data.data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getProgram({ commit }, payload) {
    return this.$http
      .get(`/programs/${payload.id}?by=${payload.key}&populate=true`)
      .then(data => {
        commit(types.GOT_PROGRAM, data.data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  setProgram({ commit, state }, id) {
    return new Promise(resolve => {
      commit(types.SET_PROGRAM, id)
      resolve(state.program._id)
    })
  },

  programSummaryUpdate({ commit }, payload) {
    const _prog = _.assign({}, payload.program)
    if (payload.product.items) {
      for (const p of payload.product.items) {
        if (payload.profile.preorders.items[p.SKU]) {
          if (!_prog.summary[p.SKU]) {
            _prog.summary[p.SKU] = {
              volume: payload.profile.preorders.items[p.SKU],
              preorders: {}
            }
          }
          _prog.summary[p.SKU].preorders[payload.user._id] = {
            qty: payload.profile.preorders.items[p.SKU],
            customize: payload.profile.preorders.details[p.SKU].customize
          }
          _prog.summary[p.SKU].volume = _.sum(
            _.map(
              i => _prog.summary[p.SKU].preorders[i].qty,
              _.keys(_prog.summary[p.SKU].preorders)
            )
          )
        }
      }
    } else {
      if (payload.profile.preorders.items[payload.product.SKU]) {
        if (!_prog.summary[payload.product.SKU]) {
          _prog.summary[payload.product.SKU] = {
            volume: payload.profile.preorders.items[payload.product.SKU],
            preorders: {}
          }
        }
        _prog.summary[payload.product.SKU].preorders[payload.user._id] = {
          qty: payload.profile.preorders.items[payload.product.SKU],
          customize:
            payload.profile.preorders.details[payload.product.SKU].customize
        }
        _prog.summary[payload.product.SKU].volume = _.sum(
          _.map(
            i => _prog.summary[payload.product.SKU].preorders[i].qty,
            _.keys(_prog.summary[payload.product.SKU].preorders)
          )
        )
      } else {
        if (_prog.summary[payload.product.SKU].preorders[payload.user._id]) {
          delete _prog.summary[payload.product.SKU].preorders[payload.user._id]
          _prog.summary[payload.product.SKU].volume = _.sum(
            _.map(
              i => _prog.summary[payload.product.SKU].preorders[i].qty,
              _.keys(_prog.summary[payload.product.SKU].preorders)
            )
          )
        }
      }
    }
    this.$http.post(`/programs/${_prog._id}`, _prog).then(data => {
      commit(types.UPDATE_PROGRAM_SUMMARY, data.data)
    })
  }
}

export default actions
