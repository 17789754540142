<template src="@/../../../templates/brand/components/templates/MainFooter.html"> </template>

<script>
import { mapState } from 'vuex'
import acl from '@/../../../templates/site/UI/app/acl-rules'
import SupportForm from './Support.vue'
export default {
  components: { SupportForm },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    isAdmin: function () {
      return acl['admin.access'](this.user.role.level)
    },
    linkToGuide: function () {
      if (this.user.role.level > 20) {
        return this.$i18n.locale === 'en'
          ? '/assets/static/files/hondagear-m-userguide.pdf'
          : '/assets/static/files/hondagear-m-mode-demploi.pdf'
      } else {
        return this.$i18n.locale === 'en'
          ? '/assets/static/files/hondagear-as-userguide.pdf'
          : '/assets/static/files/hondagear-as-mode-demploi.pdf'
      }
    }
  },
  data () {
    return {
      showTicket: false
    }
  },
  events: {
    'hide.support': function () {
      setTimeout(() => {
        this.showTicket = false
      }, 2000)
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/MainFooter.css"></style>
