import actions from './actions'
import mutations from './mutations'

const state = {
  content: [],
  pages: [],
  page: {},
  error: false,
  success: false
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
