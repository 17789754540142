import _ from 'lodash/fp'
import {
  LOGOUT,
  GOT_COLLECTIONS,
  GOT_COLLECTION,
  SET_COLLECTION,
  SHOW_COLLECTION_PRODUCTS,
  GOT_PROGRAMS,
  GOT_PROGRAM,
  SET_PROGRAM,
  SHOW_PROGRAM_PRODUCTS,
  UPDATE_PROGRAM_SUMMARY
} from '@/store/mutation-types'

const mutations = {
  [LOGOUT](state) {
    state.collections = []
    state.collection = null
    state.section = ''
    state.collectionLocale = ''
    state.programs = []
    state.program = null
  },
  [SET_COLLECTION](state, url) {
    state.collection = _.assign(
      {},
      _.find(c => c.url === url, state.collections)
    )
  },
  [GOT_COLLECTIONS](state, collections) {
    state.collections = collections.filter(c => c.active)
  },
  [GOT_COLLECTION](state, collection) {
    state.collection = collection
  },
  [SHOW_COLLECTION_PRODUCTS](state, url) {
    if (typeof url === 'string') {
      state.section = url
      state.collectionLocale = url.split('-').join('_')
    }
  },
  [GOT_PROGRAMS](state, programs) {
    state.programs = programs
  },
  [GOT_PROGRAM](state, program) {
    state.program = _.assign({}, _.isArray(program) ? program[0] : program)
  },
  [SET_PROGRAM](state, id) {
    state.program = _.assign(
      {},
      _.find(p => p._id === id, state.programs)
    )
  },
  [SHOW_PROGRAM_PRODUCTS](state, url) {
    if (typeof url === 'string') {
      state.section = url
      state.collectionLocale = url.split('-').join('_')
    }
  },
  [UPDATE_PROGRAM_SUMMARY](state, prog) {
    state.program = _.assign({}, prog)
  }
}

export default mutations
