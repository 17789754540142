import _ from 'lodash/fp'
import {
  LOGIN,
  LOGOUT,
  AUTHENTICATED_USER,
  USER_NOT_LOGGED_IN,
  SET_CONTEXT,
  PROFILE_UPDATE,
  HAVE_PREORDER,
  GOT_PROGRAMS,
  GOT_PROGRAM,
  SHOW_PROGRAM_PRODUCTS,
  CLEAR_CART,
  MERGE_CONFIG,
  FLASH_ERROR,
  REQ_ERROR
} from '@/store/mutation-types'

const mutations = {
  [LOGIN](state, user) {
    state.user = user
  },
  [LOGOUT](state) {
    state.user = _.assign({}, { authenticated: false })
  },
  [AUTHENTICATED_USER](state, user) {
    state.user = user
    // if (user.profile.lang && state.active !== user.profile.lang) {
    //   state.active = user.profile.lang
    //   Vue.config.lang = user.profile.lang
    //   document.documentElement.lang = user.profile.lang
    // }
  },
  [USER_NOT_LOGGED_IN](state) {
    state.user = _.assign({}, { authenticated: false })
  },
  [SET_CONTEXT](state, context) {
    state.navigationContext = context.path
  },
  [PROFILE_UPDATE](state, profile) {
    const _u = _.assign({}, state.user)
    _u.profile = profile
    state.user = _.assign({}, _u)
  },
  [HAVE_PREORDER](state) {
    if (!state.user.profile.preorders) {
      state.user.profile.preorders = { items: {}, details: {} }
    }
  },
  [GOT_PROGRAMS](state) {
    if (!state.user.profile.preorders) {
      state.user.profile.preorders = { items: {}, details: {} }
    }
  },
  [GOT_PROGRAM](state) {
    if (!state.user.profile.preorders) {
      state.user.profile.preorders = { items: {}, details: {} }
    }
  },
  [SHOW_PROGRAM_PRODUCTS](state) {
    if (!state.user.profile.preorders) {
      state.user.profile.preorders = { items: {}, details: {} }
    }
  },
  [CLEAR_CART](state) {
    const _cart = { items: {}, details: {} }
    const _profile = _.assign({}, state.user.profile)

    _profile.cart = _cart
    state.user.profile = _.assign({}, _profile)
  },
  [MERGE_CONFIG](state, config) {
    state.constants = _.merge(state.constants, config)
  },
  [FLASH_ERROR](state, msg) {
    state.flash = msg
  },
  [REQ_ERROR](state, err) {
    state.api_error = err
    console.error('Error: ', err)
  },
  SHOW_ALERT(state, payload) {
    state.showAlert = payload
  }
}

export default mutations
