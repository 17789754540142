import _ from 'lodash/fp'
import {
  HOME_CONFIG,
  LOGOUT,
  PAGE_MENU,
  GOT_CART,
  GOT_CUSTOMORDER_PRODUCTS,
  CLEAR_CART
} from '@/store/mutation-types'

const mutations = {
  [HOME_CONFIG] (state, data) {
    state.homeConfig = data
  },
  [LOGOUT] (state) {
    state.pagenav = []
    state.cartcount = { items: 0, units: 0, subtotal: 0, cartmirror: {} }
    state.customcount = { items: 0, units: 0, subtotal: 0, cartmirror: {} }
  },
  [PAGE_MENU] (state, pages) {
    // state.content = content,
    state.pagenav = pages
  },

  [GOT_CART] (state, payload) {
    const cart = payload.cart
    const type = payload.type
    const root = payload.root
    // console.info(' ::: test this for err', cart[0])
    // if (cart[0].err) return
    const _cartcount = _.assign({}, state.cartcount)
    const _customcount = _.assign({}, state.customcount)
    const _customcarts = _.assign({}, state.customcarts)

    if (type === 'cart') {
      _cartcount.items = _.keys(root.user.profile.cart.items).length
      _cartcount.units = _.sum(_.values(root.user.profile.cart.items))
      _cartcount.cartmirror = _.assign({}, root.user.profile.cart)
      _cartcount.subtotal = _.reduce(
        (sum, i) => {
          if (_.get('locations', i.custom)) return
          const _adjustments = i.adjustments.length
            ? _.sortBy(ad => ad.volume, i.adjustments)
            : [{ value: i.price }]
          const _adjusted = i.adjustments.length
            ? _.sortedIndexBy(
                { volume: _cartcount.cartmirror.items[i.SKU] },
                a => a.volume,
                _adjustments
              )
            : 0

          sum +=
            _adjustments[_adjusted].value * _cartcount.cartmirror.items[i.SKU]
          return sum
        },
        0,
        cart
      )
      state.cartcount = _.assign({}, _cartcount)
    } else if (type === 'custom') {
      _customcount.items = _.keys(root.user.profile.customorders.items).length
      _customcount.units = _.sum(_.values(root.user.profile.customorders.items))
      _customcount.cartmirror = root.user.profile.customorders
      _customcount.subtotal = _.reduce(
        (sum, i) => {
          if (!_.get('locations', i.custom)) return
          const _adjustments = i.adjustments.length
            ? _.sortBy(ad => ad.volume, i.adjustments)
            : [{ value: i.price }]
          const _custadjustments = i.custom.adjustments.length
            ? _.sortBy(ad => ad.volume, i.custom.adjustments)
            : [{ value: i.custom.price }]
          const _customizations = _.keys(
            _customcount.cartmirror.details[i.SKU].customizations
          ).length
          let _adjusted, _custadjusted
          _adjusted = i.adjustments.length
            ? _.findIndex(
                a => a.volume >= familyQty(i, cart, _customcount.cartmirror),
                _adjustments
              )
            : -1
          _custadjusted = i.custom.adjustments.length
            ? _.findIndex(
                a => a.volume >= familyQty(i, cart, _customcount.cartmirror),
                _custadjustments
              )
            : -1
          if (_adjusted > 0) _adjusted = _adjusted - 1
          if (_custadjusted > 0) _custadjusted = _custadjusted - 1
          // _adjusted = i.adjustments.length ? _.sortedIndexBy({ volume: _customcount.cartmirror.items[i.SKU] }, (a) => a.volume, _adjustments) : 0;
          // _custadjusted = i.custom.adjustments.length ? _.sortedIndexBy({ volume: _customcount.cartmirror.items[i.SKU] }, (a) => a.volume, _custadjustments) : 0;

          if (
            familyQty(i, cart, _customcount.cartmirror) < _adjustments[0].volume
          ) {
            return (
              sum +
              (i.price +
                i.custom.price *
                  (_customizations > 0 ? _customizations - 1 : 0)) *
                _customcount.cartmirror.items[i.SKU]
            )
          }
          if (
            familyQty(i, cart, _customcount.cartmirror) >=
            _.last(_adjustments).volume
          ) {
            return (
              sum +
              (_.last(_adjustments).value +
                _.last(_custadjustments).value *
                  (_customizations > 0 ? _customizations - 1 : 0)) *
                _customcount.cartmirror.items[i.SKU]
            )
          }
          return (
            sum +
            (_adjustments[_adjusted].value +
              _custadjustments[_custadjusted].value *
                (_customizations > 0 ? _customizations - 1 : 0)) *
              _customcount.cartmirror.items[i.SKU]
          )
        },
        0,
        cart
      )
      state.customcount = _.assign({}, _customcount)
    } else {
      _.each(c => {
        if (!_customcarts[c]) {
          _customcarts[c] = {
            items: _.keys(root.user.profile.shoppingcarts[c].items).length,
            units: _.sum(_.values(root.user.profile.shoppingcarts[c].items)),
            cartmirror: root.user.profile.shoppingcarts[c]
          }
        } else {
          _customcarts[c].items = _.keys(
            root.user.profile.shoppingcarts[c].items
          ).length
          _customcarts[c].units = _.sum(
            _.values(root.user.profile.shoppingcarts[c].items)
          )
          _customcarts[c].cartmirror = root.user.profile.shoppingcarts[c]
        }
      }, _.keys(root.user.profile.shoppingcarts))
      _.each(c => {
        if (c !== type) return
        _customcarts[c].subtotal = _.reduce(
          (sum, i) => {
            const _adjustments = i.adjustments.length
              ? _.sortBy(ad => ad.volume, i.adjustments)
              : [{ value: i.price }]
            let _adjusted
            _adjusted = i.adjustments.length
              ? _.sortedIndexBy(
                  { volume: _customcarts[c].cartmirror.items[i.SKU] },
                  a => a.volume,
                  _adjustments
                )
              : 0
            if (_.get('locations', i.custom)) {
              const _custadjustments = i.custom.adjustments.length
                ? _.sortBy(ad => ad.volume, i.custom.adjustments)
                : [{ value: i.custom.price }]
              const _customizations = _.keys(
                _customcarts[c].cartmirror.details[i.SKU].customizations
              ).length
              let _custadjusted
              _adjusted = i.adjustments.length
                ? _.findIndex(
                    a =>
                      a.volume >=
                      familyQty(i, cart, _customcarts[c].cartmirror),
                    _adjustments
                  )
                : -1
              _custadjusted = i.custom.adjustments.length
                ? _.findIndex(
                    a =>
                      a.volume >=
                      familyQty(i, cart, _customcarts[c].cartmirror),
                    _custadjustments
                  )
                : -1
              if (_adjusted > 0) _adjusted -= 1
              if (_custadjusted > 0) _custadjusted -= 1

              if (
                familyQty(i, cart, _customcarts[c].cartmirror) <
                _adjustments[0].volume
              ) {
                return (
                  sum +
                  (i.price +
                    i.custom.price *
                      (_customizations > 0 ? _customizations - 1 : 0)) *
                    _customcarts[c].cartmirror.items[i.SKU]
                )
              }
              if (
                familyQty(i, cart, _customcarts[c].cartmirror) >=
                _.last(_adjustments).volume
              ) {
                return (
                  sum +
                  (_.last(_adjustments).value +
                    _.last(_custadjustments).value *
                      (_customizations > 0 ? _customizations - 1 : 0)) *
                    _customcarts[c].cartmirror.items[i.SKU]
                )
              }
              return (
                sum +
                (_adjustments[_adjusted].value +
                  _custadjustments[_custadjusted].value *
                    (_customizations > 0 ? _customizations - 1 : 0)) *
                  _customcarts[c].cartmirror.items[i.SKU]
              )
            } else {
              sum +=
                _adjustments[_adjusted].value *
                _customcarts[c].cartmirror.items[i.SKU]
              return sum
            }
          },
          0,
          cart
        )
      }, _.keys(root.user.profile.shoppingcarts))
      state.customcarts = _.assign({}, _customcarts)
    }
  },
  [GOT_CUSTOMORDER_PRODUCTS] (state, custom) {
    const _customcount = _.assign({}, state.customcount)
    _customcount.subtotal = _.reduce(
      (sum, i) => {
        if (!_.get('locations', i.custom)) return
        const _adjustments = i.adjustments.length
          ? _.sortBy(ad => ad.volume, i.adjustments)
          : [{ value: i.price }]
        const _custadjustments = i.custom.adjustments.length
          ? _.sortBy(ad => ad.volume, i.custom.adjustments)
          : [{ value: i.custom.price }]
        const _customizations = _.keys(
          _customcount.cartmirror.details[i.SKU].customizations
        ).length
        let _adjusted, _custadjusted
        _adjusted = i.adjustments.length
          ? _.findIndex(
              a => a.volume >= familyQty(i, custom, _customcount.cartmirror),
              _adjustments
            )
          : -1
        _custadjusted = i.custom.adjustments.length
          ? _.findIndex(
              a => a.volume >= familyQty(i, custom, _customcount.cartmirror),
              _custadjustments
            )
          : -1
        if (_adjusted > 0) _adjusted = _adjusted - 1
        if (_custadjusted > 0) _custadjusted = _custadjusted - 1
        // _adjusted = i.adjustments.length ? _.sortedIndexBy({ volume: _customcount.cartmirror.items[i.SKU] }, (a) => a.volume, _adjustments) : 0;
        // _custadjusted = i.custom.adjustments.length ? _.sortedIndexBy({ volume: _customcount.cartmirror.items[i.SKU] }, (a) => a.volume, _custadjustments) : 0;

        if (
          familyQty(i, custom, _customcount.cartmirror) < _adjustments[0].volume
        ) {
          return (
            sum +
            (i.price +
              i.custom.price *
                (_customizations > 0 ? _customizations - 1 : 0)) *
              _customcount.cartmirror.items[i.SKU]
          )
        }
        if (
          familyQty(i, custom, _customcount.cartmirror) >=
          _.last(_adjustments).volume
        ) {
          return (
            sum +
            (_.last(_adjustments).value +
              _.last(_custadjustments).value *
                (_customizations > 0 ? _customizations - 1 : 0)) *
              _customcount.cartmirror.items[i.SKU]
          )
        }
        return (
          sum +
          (_adjustments[_adjusted].value +
            _custadjustments[_custadjusted].value *
              (_customizations > 0 ? _customizations - 1 : 0)) *
            _customcount.cartmirror.items[i.SKU]
        )
      },
      0,
      custom
    )
    state.customcount = _.assign({}, _customcount)
  },
  [CLEAR_CART] (state, cart) {
    if (cart === 'cart') {
      state.cartcount = _.assign(
        {},
        { items: 0, units: 0, subtotal: 0, cartmirror: {} }
      )
    } else if (cart === 'customorders') {
      state.customcount = _.assign(
        {},
        { items: 0, units: 0, subtotal: 0, cartmirror: {} }
      )
    } else {
      const _c = _.assign({}, state.customcarts)
      _c[cart] = _.assign(
        {},
        { items: 0, units: 0, subtotal: 0, cartmirror: {} }
      )
      state.customcarts = _c
    }
  }
}

function familyQty (item, products, cart) {
  return _.sum(
    _.map(
      i =>
        item.familyKey === i.familyKey &&
        item.variationField.key === i.variationField.key
          ? cart.items[i.SKU]
          : 0,
      products
    )
  )
}

export default mutations
