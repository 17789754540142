import actions from './actions'
import mutations from './mutations'

const state = {
  locations: [],
  location: null
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
