// import _ from 'lodash/fp'
// import productUtil from '../../../lib/product-utils'
import actions from './actions'
import mutations from './mutations'

const state = {
  products: [],
  productFamilies: {},
  productFamily: {},
  familyVariations: [],
  listFamilies: [],
  atomicFamilies: [],
  atomicProduct: {},
  customimages: [],
  // primaryProduct: null,
  product: null,
  allCollectionProducts: [],
  atomicFamilyCache: [],
  familyVariationCache: [],
  preorderProducts: [],
  customorderProducts: [],
  filteredSet: []
}

export default {
  state,
  actions,
  mutations,
  getters: {}
}
