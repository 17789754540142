<template src="@/../../../templates/brand/components/templates/HomePublic.html"> </template>

<script>
//
import { mapState } from 'vuex'

export default {
  name: 'HomePublic',
  created () {
    let route = this.$route.name 
      ? this.$router.options.routes.find(r => r.name === this.$route.name)
      : this.$router.options.routes.find(r => r.path === this.$route.path)
    if (!this.user.authenticated && route.requiresAuth) this.$router.push({ name: 'Login' })
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      appconfig: ({ App }) => App.constants.appconfig,
      user: ({ App }) => App.user
    }),
  }
}
</script>

<style></style>
